<template>
  <div>
    <h1 class="text-2xl font-semibold text-gray-900 mb-4">
      Duplicaten facturaties
      (<select v-model="algo" class="inline">
        <option v-for="algoName in Object.keys(algoEndpoints)" :key="algoName" :value="algoName">
          {{ algoName }}
        </option>
      </select>)
    </h1>
    <div class="hidden lg:block">
      <div class="bg-red-100 border-red-300 text-red-900 text-lg border rounded-lg px-8 py-4 mb-12">
        <strong>Opgelet!</strong> Deze aanpassingen zijn onomkeerbaar! Wees zeker!
      </div>

      <UILoading v-show="loading" />
      <p v-if="!count" v-show="!loading" class="no-data block text-center">
        Geen duplicaten meer gevonden
      </p>
      <div v-else v-show="!loading" class="flex flex-col gap-8">
        <div v-if="item?.first && item?.second" :key="JSON.stringify(chosen)" class="grid lg:grid-cols-2 gap-8">
          <ul class="card">
            <li v-for="(val, key) in item.first" :key="`${key}${val}${offset}`" class="mb-1 flex flex-row gap-2 items-center">
              <UICheckbox :disabled="ignoreKeys.includes(key) || item.first[key] == item.second[key]" :model-value="chosen[key] === val" @update:model-value="chosen[key] = $event ? item.first[key] : item.second[key]" />
              <strong class="inline-block w-32 mr-4">{{ key.toUpperCase() }}</strong>
              <span>{{ val }}</span>
            </li>
          </ul>
          <ul class="card">
            <li v-for="(val, key) in item.second" :key="`${key}${val}${offset}`" class="mb-1 flex flex-row gap-2 items-center">
              <UICheckbox :disabled="ignoreKeys.includes(key) || item.first[key] == item.second[key]" :model-value="chosen[key] === val" @update:model-value="chosen[key] = $event ? item.second[key] : item.first[key]" />
              <strong class="inline-block w-32 mr-4">{{ key.toUpperCase() }}</strong>
              <span>{{ val }}</span>
            </li>
          </ul>
        </div>

        <div v-if="Object.keys(chosen || {}).length" class="w-1/2 mx-auto">
          <h2 class="mb-4">
            Gekozen waarden
          </h2>
          <ul class="card mr-8">
            <li v-for="(val, key) in chosen" :key="`${key}${val}`" class="mb-1 flex flex-row gap-2 items-center">
              <strong class="inline-block w-32 mr-4">{{ key.toUpperCase() }}</strong>
              <span>{{ val }}</span>
            </li>
          </ul>

          <div class="flex flex-col lg:flex-row gap-8 justify-center">
            <button
              type="submit"
              :disabled="page <= 1"
              class="w-20 btn error mt-4"
              @click.prevent.stop="page -= 1"
            >
              <i class="fas fa-arrow-left"></i> Back
            </button>
            <button type="submit" class="w-24 lg:w-32 xl:w-48 btn success mt-4 py-2" @click.prevent.stop="handleOpslaan()">
              Opslaan
            </button>
            <button
              type="submit"
              :disabled="page >= count"
              class="w-20 btn error mt-4"
              @click.prevent.stop="page += 1"
            >
              Skip <i class="fas fa-arrow-right"></i>
            </button>
          </div>
        </div>

        <UITablePagination
          v-model:page="page"
          v-model:offset="offset"
          class="mt-2"
          hide-limits
          :count="count"
          :limit="limit"
          label-pagina="Mogelijkheid"
        />
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed, defineAsyncComponent, ref, watch } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import clone from 'just-clone'

import UICheckbox from '@/components/UI/Input/Checkbox.vue'
import UILoading from '@/components/UI/Loading.vue'

import useApi from '@/hooks/useApi'
import useModal from '@/hooks/useModal'
import usePaginatedApi from '@/hooks/usePaginatedApi'

const UITablePagination = defineAsyncComponent(() => import('@/components/UI/Table/Pagination.vue'))

const api = useApi()
const route = useRoute()
const router = useRouter()
const [openFixedModal] = useModal({ fixed: true })
const page = ref(1)

const ignoreKeys = [
  'id',
  'creation_date',
  'update_date',
]

const algoEndpoints = {
  uuid: 'DUPLICATE_FACTURATIES_NEXT_UUID',
  adres: 'DUPLICATE_FACTURATIES_NEXT_ADRES',
  btw: 'DUPLICATE_FACTURATIES_NEXT_BTW',
  name: 'DUPLICATE_FACTURATIES_NEXT_NAME',
}

const algo = ref(route?.params?.algo?.toLowerCase())
const algoEndpoint = computed(() => algoEndpoints[algo.value] || null)

const { limit, offset, count, items, getData, loading } = usePaginatedApi(algoEndpoint.value, {}, {
  defaultLimit: 1,
})

watch(algo, () => {
  router.push({ name: route.name, params: { algo: algo.value.toLowerCase() }, force: true }).then(() => {
    router.go()
  })
})

const item = computed(() => items.value?.length ? items.value[0] : null)
const chosen = ref(null)
watch(items, () => {
  if (!item?.value?.first) {
    chosen.value = null
    return
  }

  const second = item.value.second
  const first = item.value.first
  const obj = clone(first)
  delete obj.id
  if (second?.creation_date < first.creation_date) obj.creation_date = second.creation_date
  if (second?.update_date > first.update_date) obj.update_date = second.update_date
  chosen.value = obj
})

const handleOpslaan = () => {
  const callback = () => {
    const data = clone(chosen.value)
    data.first_id = item?.value?.first?.id
    data.second_id = item?.value?.second?.id
    api('DUPLICATE_FACTURATIES_MERGE', data).then(res => {
      getData()
      return res
    })
  }
  openFixedModal('BEVESTIG_ACTIE', { message: 'Weet je zeker deze facturatie gegevens te willen mergen?', callback })
}
</script>
